var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"psychology-schedule-dialog",attrs:{"title":(_vm.preTitle + "学期排班表"),"visible":_vm.show,"close-on-click-modal":false,"before-close":_vm.cancel,"width":"1320px"},on:{"update:visible":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('cancel-popover',{ref:"cancelRef",attrs:{"disabled":_vm.loadData.loading,"update-flag":_vm.updateFlag},on:{"update:disabled":function($event){return _vm.$set(_vm.loadData, "loading", $event)}}}),_c('el-button',{attrs:{"disabled":!_vm.formData.weekCount,"type":"primary"},on:{"click":_vm.save}},[_vm._v("批量生成")])]},proxy:true}])},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"60px"}},[_c('el-steps',{attrs:{"direction":"vertical","active":_vm.curSteps}},[_c('el-step',{attrs:{"title":"步骤 1：编辑通用排班模板"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('el-collapse',{staticClass:"mt-1 mb-1",model:{value:(_vm.activeTab2),callback:function ($$v) {_vm.activeTab2=$$v},expression:"activeTab2"}},[_c('el-collapse-item',{attrs:{"title":"排班模板配置","name":"1"}},[_c('el-row',{staticStyle:{"margin-top":"2em"}},[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":"值班教师","label-width":"90px"}},[_c('el-select',{attrs:{"clearable":"","multiple":"","multiple-limit":4},model:{value:(_vm.teacherIds),callback:function ($$v) {_vm.teacherIds=$$v},expression:"teacherIds"}},_vm._l((_vm.teacherList),function(ref){
var name = ref.name;
var userId = ref.userId;
return _c('el-option',{key:userId,attrs:{"label":name,"value":userId}})}),1)],1)],1),_c('el-col',{attrs:{"span":14}},[_c('el-button',{staticClass:"fr mr-1",on:{"click":function($event){return _vm.clear('scheduleModel')}}},[_vm._v("清空排班表")])],1)],1),_c('el-divider',[_vm._v("排班表")]),(_vm.scheduleModel.length)?_c('div',{staticClass:"schedule-grid psycho"},[_c('div',{staticClass:"schedule-grid__header flex",staticStyle:{"background":"#f3f5fb"}},[_c('div',{staticClass:"schedule-grid__label",staticStyle:{"line-height":"24px","text-align":"center"}},[_c('b',[_vm._v("时间段")])]),_vm._l((_vm.scheduleModel),function(item,index){return _c('div',{key:index,staticClass:"schedule-grid__label"},[_c('b',{staticClass:"week-name"},[_vm._v("周"+_vm._s(_vm.$store.state.systemParam.weekList[+item.weekDayName])+" "),_c('el-link',{staticClass:"el-icon-delete",attrs:{"title":"清空当日排班","type":"danger"},on:{"click":function($event){return _vm.clearColumn(item)}}})],1)])})],2),_c('div',{staticClass:"schedule-grid__body flex-column"},[_c('div',{staticClass:"schedule-grid__wrap  flex schedule-grid__body-top"},[_c('div',{staticClass:"schedule-grid__column",staticStyle:{"background":"#f3f5fb"}},_vm._l((_vm.classHours),function(item,index){return _c('div',{key:index,staticClass:"schedule-grid__item time-stamp"},[_c('b',[_vm._v(_vm._s(item))])])}),0),_vm._l((_vm.scheduleModel),function(item){return _c('div',{key:item.id,staticClass:"schedule-grid__column"},_vm._l((item.list),function(subItem,subIndex){return _c('div',{key:subIndex,staticClass:"schedule-grid__item flex-column days-item",on:{"click":function($event){return _vm.getCheck(subItem,item)}}},[(subItem.teacherInfoList)?_vm._l((subItem.teacherInfoList),function(ref){
var userId = ref.userId;
var name = ref.name;
return _c('el-tag',{key:userId,attrs:{"type":_vm.getTeacherTag(userId)}},[_vm._v(" "+_vm._s(name)+" ")])}):_vm._e()],2)}),0)})],2)])]):_vm._e()],1)],1)]},proxy:true}])}),_c('el-step',{attrs:{"title":"步骤 2：填写排班表参数，根据周期批量生成排班表"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('el-collapse',{staticClass:"mt-1 mb-1",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('el-collapse-item',{attrs:{"title":"排班表参数设置","name":"1"}},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"校区","prop":"campusId","label-width":"90px"}},[_c('el-radio-group',{ref:"radioRef",on:{"change":function($event){_vm.curSteps=1}},model:{value:(_vm.formData.campusId),callback:function ($$v) {_vm.$set(_vm.formData, "campusId", $$v)},expression:"formData.campusId"}},_vm._l((_vm.campusList),function(ref){
var campusName = ref.campusName;
var id = ref.id;
return _c('el-radio-button',{key:id,attrs:{"label":id}},[_vm._v(_vm._s(campusName)+" ")])}),1)],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"学年","prop":"schoolYear","label-width":"90px"}},[_c('tool-tips-group',{attrs:{"is-flex":"","tips-content":"排班周期根据学年做出限制。"}},[_c('el-select',{on:{"change":function (){ return _vm.formData.dateRange=[]; }},model:{value:(_vm.formData.schoolYear),callback:function ($$v) {_vm.$set(_vm.formData, "schoolYear", $$v)},expression:"formData.schoolYear"}},_vm._l((_vm.schoolYearList),function(ref){
var gradeName = ref.gradeName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":gradeName,"value":gradeName}})}),1)],1)],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"学期","prop":"term"}},[_c('el-select',{model:{value:(_vm.formData.term),callback:function ($$v) {_vm.$set(_vm.formData, "term", $$v)},expression:"formData.term"}},_vm._l((_vm.termList),function(item){return _c('el-option',{key:item.id,attrs:{"label":("第" + (item.paramValue) + "学期"),"value":item.paramValue}})}),1)],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"学期周期","prop":"dateRange","label-width":"90px"}},[_c('el-date-picker',{attrs:{"disabled":!_vm.formData.schoolYear,"unlink-panels":"","type":"daterange","range-separator":"至","start-placeholder":"学期开始日期","end-placeholder":"学期结束日期","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions,"default-value":_vm.rangeDefaultValue},model:{value:(_vm.formData.dateRange),callback:function ($$v) {_vm.$set(_vm.formData, "dateRange", $$v)},expression:"formData.dateRange"}})],1)],1)],1),(_vm.formData.weekCount)?_c('el-button',{staticClass:"ml-1",attrs:{"type":"primary","plain":""},on:{"click":_vm.updateSchedule}},[_vm._v("重新生成 ")]):_c('el-button',{staticClass:"ml-1",attrs:{"type":"primary","plain":""},on:{"click":_vm.renderTermSchedule}},[_vm._v("生成需求排班表 ")])],1)],1)]},proxy:true}])}),_c('el-step',{attrs:{"title":"步骤 3：确认每周排班，编辑节假日等特殊周次的排班表"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('batch-schedule',{ref:"batchScheduleRef",attrs:{"form-data":_vm.formData,"model":_vm.scheduleModel,"start-day-of-monday":_vm.startDayOfMonday,"all-schedule-list":_vm.allScheduleList,"teacher-list":_vm.teacherList},on:{"update:allScheduleList":function($event){_vm.allScheduleList=$event},"update:all-schedule-list":function($event){_vm.allScheduleList=$event}}})]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }