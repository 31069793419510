<!--  心理咨询室  -->
<template>
  <div class="psychology-schedule" v-loading="loading">
    <el-tabs v-model="curTab" @tab-click="onChange">
      <el-tab-pane label="本周班表" name="1">
        <current-schedule ref="currentScheduleRef" />
      </el-tab-pane>
      <el-tab-pane v-if="isEditor" label="学期排班" name="2">
        <schedule-record />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CurrentSchedule from '@/views/pages/studentManage/psychology-schedule/components/currentSchedule.vue'
import ScheduleRecord from '@/views/pages/studentManage/psychology-schedule/components/scheduleRecord.vue'

export default {
  name: 'psychologySchedule',
  components: {
    ScheduleRecord,
    CurrentSchedule
  },
  data() {
    return {
      isEditor: false, // 编辑状态禁止跳转切换
      loading: false, // 遮罩
      curTab: '1'
    }
  },
  mounted() {
    this.isEditor = this.$store.state.login.permissionTable.includes('psycho:schedule:add')
  },
  methods: {
    onChange() {
      if (this.$refs.currentScheduleRef.isEditing) {
        this.$refs.currentScheduleRef.isEditing = false
        this.$refs.currentScheduleRef.renderTable()
      }
    }
  }
}
</script>
<style lang="scss">
.psychology-schedule {
  padding: 16px;
  background: #fff;
  min-width: 760px;
  min-height: 400px;
}
</style>
