<!--  历史班表  -->
<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="['campus','term','schoolYear']"
        @on-search="renderTable(1)">
      </view-search-form>
      <div class="header-button">
        <!--     新增需求请求两个接口：    -->
        <el-button v-permission="['psycho:schedule:add']" type="primary" size="small" @click="showDialog"
        >新增学期排班表
        </el-button>
      </div>
    </template>
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column prop="campusName" label="校区" width="100" />
      <el-table-column label="周期" min-width="200">
        <template v-slot="{row}">
          {{ row.startTime }} 至 {{ row.endTime }}
        </template>
      </el-table-column>
      <el-table-column label="学期" min-width="120">
        <template v-slot="{row}">
          第{{ row.term }}学期
        </template>
      </el-table-column>
      <el-table-column prop="schoolYear" label="学年" min-width="120" />
      <el-table-column label="操作" width="190" align="center">
        <template v-slot="{row}">
          <el-button type="primary" size="mini" @click="showEditDialog(row)">编辑</el-button>
          <el-button v-permission="['psycho:schedule:del']"
                     size="mini"
                     type="danger"
                     @click="del(delScheduleRecordByIdApi, row.id,`学期排班表(${row.campusName}${row.startTime} 至 ${row.endTime})`)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增时  -->
    <psychology-schedule-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable"/>
    <!--  编辑  -->
    <edit-psycho-schedule-dialog ref="editDialogRef" :visible.sync="visibleEditDialog" v-if="visibleEditDialog" @on-close="renderTable"/>

  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { pageScheduleRecordApi, delScheduleRecordByIdApi } from '@/api/student-manage/psychology-schedule-api'
import EditPsychoScheduleDialog
  from '@/views/pages/studentManage/psychology-schedule/components/editPsychoScheduleDialog.vue'
import PsychologyScheduleDialog
  from '@/views/pages/studentManage/psychology-schedule/components/psychologyScheduleDialog.vue'

export default {
  name: 'scheduleRecord',
  mixins: [tableView],
  components: {
    PsychologyScheduleDialog,
    EditPsychoScheduleDialog
  },
  data() {
    return {
      visibleEditDialog: false,
      isEditor: false, // 编辑权限
      queryInfo: {
        collegeId: null,
        schoolYear: null,
        term: null,
        offFlag: null,
        campusId: null
      },
      gradeYearList: []
    }
  },
  async mounted() {
    this.isEditor = this.$store.state.login.permissionTable.includes('psycho:teacher:edit')
    await this.renderTable(1)
  },
  methods: {
    delScheduleRecordByIdApi, // 删除api
    async renderTable(pageNum) {
      await this.getTableData(pageScheduleRecordApi, pageNum)
    },
    showEditDialog(row) {
      this.visibleEditDialog = true
      this.$nextTick(() => {
        this.$refs.editDialogRef.id = row.id
      })
    }
  }
}
</script>
