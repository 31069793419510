// 心理咨询室 排班管理
import { isEqual } from 'lodash'
import { listPsychologyTeacherApi } from '@/api/student-manage/psychology-teacher-api'

export default {
  data() {
    return {
      classHours: ['第一、二节', '第三、四节', '第五、六节', '第七、八节'],
      teacherIds: [], // 选中的教师ids
      teacherList: [], // 心理咨询室教师
      campusList: [] // 校区list
    }
  },
  methods: {
    // 获取校区
    async getCampusList() {
      try {
        const res = await this.$http.getCampusListQuery({})
        this.campusList = res.data
      } catch (e) {
      }
    },
    // 给不同的老师标记不同的颜色
    getTeacherTag(userId) {
      if (!this.teacherList.length) return
      return this.teacherList.find(item => item.userId === userId)?.tagType || 'info'
    },
    // 咨询教师list
    async getTeacherList() {
      try {
        const { data } = await listPsychologyTeacherApi()
        const typeList = ['primary', 'success', 'warning', 'pink', 'cyan', 'purple', 'danger']
        data.forEach((item, index) => {
          item.tagType = index > typeList.length - 1 ? typeList[index - typeList.length - 1] : typeList[index]
        })
        this.teacherList = data
      } catch (e) {
        console.error(e)
      }
    },
    /* 赋值教师
    *  type=model 编辑模板 edit=编辑本周排班  add=新增排班
    * */
    handleCheck(item, type = 'edit') {
      if (type === 'edit' && !this.isEditing) return
      // 获取选中的教师id
      if (this.teacherIds?.length) {
        this.teacherIds = this.teacherIds.sort()
        const _selects = this.teacherList.filter(item => this.teacherIds.includes(item.userId))
        if (item?.teacherInfoList?.length) {
          const _origin = JSON.parse(item.userIdJson).sort()
          if (isEqual(_origin, this.teacherIds)) {
            // 一样，则清空
            item.userIdJson = '[]'
            this.$set(item, 'teacherInfoList', [])
          } else {
            // 不一样则替换
            this.$confirm('是否确认修改值班教师？', '提示').then(() => {
              item.userIdJson = JSON.stringify(this.teacherIds)
              item.teacherInfoList = _selects
            }).catch(() => {
              return false
            })
          }
        } else {
          // 原本为空，则直接赋值
          item.userIdJson = JSON.stringify(this.teacherIds)
          this.$set(item, 'teacherInfoList', _selects)
        }
      } else {
        if (item?.teacherInfoList?.length) {
          // 未选择，则清空
          item.userIdJson = '[]'
          this.$set(item, 'teacherInfoList', [])
        } else {
          this.$message.warning({
            message: '请先选择值班教师！',
            group: true
          })
          return false
        }
      }
    },
    // 清空一天的数据
    clearColumn(item) {
      this.$confirm('是否确认清空当日排班表？', '清空').then(() => {
        item.list.forEach(child => {
          this.$set(child, 'teacherInfoList', [])
          this.$set(child, 'userIdJson', '[]')
        })
      })
    },
    // 清空 本周
    clear(target) {
      this.$confirm('是否确认清空本周排班表？', '清空').then(() => {
        this[target].forEach(item => {
          item.list.forEach(child => {
            this.$set(child, 'teacherInfoList', [])
            this.$set(child, 'userIdJson', '[]')
          })
        })
      })
    }
  }
}
