<template>
  <div>
    <el-dialog
      v-loading="loading"
      class="psychology-schedule-dialog"
      :title="`${preTitle}学期排班表`"
      :visible.sync="show"
      :close-on-click-modal="false"
      :before-close="cancel"
      width="1320px"
    >
      <el-form ref="form" :model="formData" :rules="rules" label-width="60px">
        <el-steps direction="vertical" :active="curSteps">
          <el-step title="步骤 1：编辑通用排班模板">
            <template #description>
              <el-collapse class="mt-1 mb-1" v-model="activeTab2">
                <el-collapse-item title="排班模板配置" name="1">
                  <el-row style="margin-top: 2em; ">
                    <el-col :span="10">
                      <el-form-item label="值班教师" label-width="90px" style="margin-bottom: 0;">
                        <el-select v-model="teacherIds" clearable multiple :multiple-limit="4">
                          <el-option
                            v-for="{name,userId} in teacherList"
                            :label="name"
                            :value="userId"
                            :key="userId" />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="14">
                      <el-button class="fr mr-1" @click="clear('scheduleModel')">清空排班表</el-button>
                    </el-col>
                  </el-row>
                  <!--  模板 点击生成排班表后 会按照模板生成每周班表-->
                  <el-divider>排班表</el-divider>
                  <div v-if="scheduleModel.length" class="schedule-grid psycho">
                    <div class="schedule-grid__header flex" style="background: #f3f5fb;">
                      <div class="schedule-grid__label" style="line-height: 24px;text-align: center;"><b>时间段</b>
                      </div>
                      <div class="schedule-grid__label" v-for="(item,index) in scheduleModel" :key="index">
                        <b class="week-name">周{{ $store.state.systemParam.weekList[+item.weekDayName] }}
                          <el-link title="清空当日排班" type="danger" class="el-icon-delete"
                                   @click="clearColumn(item)"></el-link>
                        </b>
                      </div>
                    </div>
                    <div class="schedule-grid__body flex-column">
                      <div class="schedule-grid__wrap  flex schedule-grid__body-top">
                        <div class="schedule-grid__column" style="background: #f3f5fb;">
                          <div class="schedule-grid__item time-stamp" v-for="(item,index) in classHours"
                               :key="index">
                            <b>{{ item }}</b>
                          </div>
                        </div>
                        <div class="schedule-grid__column" v-for="item in scheduleModel" :key="item.id">
                          <div class="schedule-grid__item flex-column days-item"
                               v-for="(subItem,subIndex) in item.list" :key="subIndex" @click="getCheck(subItem,item)">
                            <template v-if="subItem.teacherInfoList">
                              <el-tag v-for="{userId,name} in subItem.teacherInfoList" :key="userId"
                                      :type="getTeacherTag(userId)">
                                {{ name }}
                              </el-tag>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </template>
          </el-step>
          <el-step title="步骤 2：填写排班表参数，根据周期批量生成排班表">
            <template #description>
              <el-collapse class="mt-1 mb-1" v-model="activeTab">
                <el-collapse-item title="排班表参数设置" name="1">
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="校区" prop="campusId" label-width="90px">
                        <el-radio-group ref="radioRef"
                                        v-model="formData.campusId" @change="curSteps=1">
                          <el-radio-button v-for="{campusName, id} in campusList" :key="id" :label="id">{{ campusName }}
                          </el-radio-button>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="学年" prop="schoolYear" label-width="90px">
                        <tool-tips-group is-flex tips-content="排班周期根据学年做出限制。">
                          <el-select v-model="formData.schoolYear"
                                     @change="()=>formData.dateRange=[]">
                            <el-option v-for="{gradeName, id} in schoolYearList" :key="id" :label="gradeName"
                                       :value="gradeName" />
                          </el-select>
                        </tool-tips-group>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="学期" prop="term">
                        <el-select v-model="formData.term">
                          <el-option v-for="item in termList" :key="item.id" :label="`第${item.paramValue}学期`"
                                     :value="item.paramValue" />
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="学期周期" prop="dateRange" label-width="90px">
                        <el-date-picker
                          :disabled="!formData.schoolYear"
                          v-model="formData.dateRange"
                          unlink-panels
                          type="daterange"
                          range-separator="至"
                          start-placeholder="学期开始日期"
                          end-placeholder="学期结束日期"
                          value-format="yyyy-MM-dd"
                          :picker-options="pickerOptions"
                          :default-value="rangeDefaultValue"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-button v-if="formData.weekCount" class="ml-1" type="primary" plain
                             @click="updateSchedule">重新生成
                  </el-button>
                  <el-button v-else class="ml-1" type="primary" plain
                             @click="renderTermSchedule">生成需求排班表
                  </el-button>
                </el-collapse-item>
              </el-collapse>
            </template>
          </el-step>
          <el-step title="步骤 3：确认每周排班，编辑节假日等特殊周次的排班表">
            <template #description>
              <batch-schedule
                ref="batchScheduleRef"
                :form-data="formData"
                :model="scheduleModel"
                :start-day-of-monday="startDayOfMonday"
                :all-schedule-list.sync="allScheduleList"
                :teacher-list="teacherList" />
            </template>
          </el-step>
        </el-steps>
      </el-form>
      <template #footer>
        <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
        <el-button :disabled="!formData.weekCount" type="primary" @click="save">批量生成</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { getGradeList } from '@/api/grade'
import { cloneDeep, throttle, isEqual } from 'lodash'
import { getParamsList } from '@/api/sysParams'
import BatchSchedule from '@/views/pages/studentManage/psychology-schedule/components/batchSchedule.vue'
import {
  batchSavePsychoScheduleApi,
  saveScheduleRecordByIdApi,
  delScheduleRecordByIdApi
} from '@/api/student-manage/psychology-schedule-api'
import psychoSchedule from '@/vue/mixins/psycho-schedule'

export default {
  name: 'psychologyScheduleDialog',
  components: { BatchSchedule },
  mixins: [dialog, psychoSchedule],
  props: {
    departmentList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      curSteps: 0, // 步骤条
      loading: false,
      visibleModel: false,
      activeTab: '1', // 折叠工具栏
      activeTab2: '1', // 折叠工具栏
      schoolYearList: [], // 学年list
      termList: [],
      scheduleModel: [], // 打开弹窗后的排班表模板，只有一周的，用于批量生成时获取对应格子的教师信息
      allScheduleList: [], // 根据formData.周期批量生成的排班表，提交时需要遍历后把list提交
      startDayOfMonday: '',
      formData: {
        id: '',
        week: '',
        startTime: '',
        endTime: '',
        campusId: '',
        schoolYear: '',
        term: '',
        scheduleTemplate: '', // 模板
        weekCount: 0, // 获取生成的周次总数
        dateRange: []
      },
      rules: {
        dateRange: {
          required: true,
          message: '请选择排班周期',
          trigger: 'blur'
        },
        schoolYear: {
          required: true,
          message: '请选择学年',
          trigger: 'blur'
        },
        campusId: {
          required: true,
          message: '请选择校区',
          trigger: 'blur'
        },
        term: {
          required: true,
          message: '请选择学期',
          trigger: 'blur'
        }
      },
      pickerOptions: {
        disabledDate: (time) => {
          const fullYear = time.getFullYear()
          if (this.formData.schoolYear) {
            const _limits = this.formData.schoolYear.split('-')
            const _startYear = +_limits[0]
            const _endYear = +_limits[1]
            return fullYear < _startYear || fullYear > _endYear
          } else {
            return false
          }
        }
      }
    }
  },
  computed: {
    rangeDefaultValue() {
      // 看看哪个日期是当年，就优先拿哪个
      const _years = this.formData.schoolYear.split('-')
      const _targetYear = new Date().getFullYear() === +_years[1] ? _years[1] : _years[0]
      return `${_targetYear.split('-')[0]}-${new Date().getMonth() + 1}-01`
    }
  },
  async mounted() {
    await Promise.all([
      this.getTeacherList(),
      this.getCampusList(),
      this.getGradeYearList(),
      this.getTermList()
    ])
    this.setSchedule()
    this.getCurTerm()
  },
  methods: {
    // 获取学年
    async getGradeYearList() {
      try {
        const { data } = await getGradeList({})
        this.schoolYearList = data.list
      } catch (e) {
      }
    },
    // 获取学期--termList
    async getTermList() {
      try {
        const { data } = await getParamsList({ keyword: 'term' })
        this.termList = data.list
      } catch (e) {
        console.error(e)
      }
    },
    // 设置默认的学期
    getCurTerm() {
      const _month = +this.$moment().format('MM')
      // 当年3月-当年8月为第二学期，当年9月-次年2月为第一学期
      this.formData.term = (_month >= 3 && _month <= 8) ? '2' : '1'
    },
    /* 生成排班表
    *  @params {array} arr 获取的格子日期班表
    * */
    setSchedule() {
      const result = []
      for (let i = 1; i <= 7; i++) {
        const list = []
        this.classHours.forEach(classHour => {
          list.push({
            weekDayName: i + '', // 周几
            classHourName: classHour,
            userIdJson: '[]',
            teacherInfoList: []
          })
        })
        result.push({
          weekDayName: i + '',
          list
        })
      }
      this.scheduleModel = result
    },
    // 重新生成
    updateSchedule() {
      this.$confirm('是否确认放弃已生成的排班数据，重新生成？', '重新生成').then(() => {
        this.renderTermSchedule()
      }).catch(() => {
        this.$message.info('已取消重新生成')
      })
    },

    /* 如果模板修改，则需要把 allScheduleList/scheduleList里的数据也同步上，因此需要单独加方法 改 getCheck  */
    changeSchedule(parentDay) {
      if (this.allScheduleList.length) {
        for (let i = 0; i < this.allScheduleList.length; i++) {
          if (this.allScheduleList[i].weekDayName === parentDay.weekDayName) {
            this.allScheduleList[i] = cloneDeep(parentDay)
          }
        }
      }
    },
    // 生成学期排班表
    renderTermSchedule() {
      this.$refs.form.validate(async valid => {
          if (!valid) return false
          this.curSteps = 2
          this.activeTab2 = ''
          this.loading = true
          this.formData.scheduleTemplate = JSON.stringify(this.scheduleModel)
          this.formData.startTime = this.formData.dateRange[0]
          this.formData.endTime = this.formData.dateRange[1]
          // 开始日期 周一的日期，用于保证周次值的准确度
          // 【开始，结束日期】
          const [_startDate, _endDate] = this.formData.dateRange
          // 获取开始日期 的周一的日期
          this.startDayOfMonday = this.$moment(_startDate).startOf('week').format('YYYY-MM-DD')
          // 根据上面获取的周一日期，计算周次
          this.formData.weekCount = this.$moment(_endDate).diff(this.startDayOfMonday, 'week') + 1
          this.loading = false
          this.$nextTick(() => {
            // 组件使用了 mixins 所以只能在这里赋值teacherList,否则props 和data参数重复
            this.$refs.batchScheduleRef.teacherList = this.teacherList
            this.$refs.batchScheduleRef.monday = this.$moment(this.formData.dateRange[0]).startOf('week').format('YYYY-MM-DD')
            this.$refs.batchScheduleRef.renderSchedule()
          })
        }
      )
    },
    getCheck(classHour, parentDay) {
      if (this.$refs.batchScheduleRef.scheduleList.length) {
        this.$confirm('修改模板会把周期中所有数据的同步修改，是否继续？', '提示').then(() => {
          this.handleCheck(classHour, parentDay)
        })
      } else {
        this.handleCheck(classHour, parentDay)
      }
    },
    /* 赋值教师
   *  type=model 编辑模板 edit=编辑本周排班  add=新增排班
   * */
    handleCheck(item, parentDay) {
      // 获取选中的教师id
      if (this.teacherIds?.length) {
        this.teacherIds = this.teacherIds.sort()
        if (item?.teacherInfoList?.length) {
          const _origin = JSON.parse(item.userIdJson).sort()
          if (isEqual(_origin, this.teacherIds)) {
            // 一样，则清空
            this.setItem(item, [], parentDay)
          } else {
            // 不一样则替换
            if (this.$refs.batchScheduleRef.scheduleList.length) {
              this.setItem(item, this.teacherIds, parentDay)
            } else {
              this.$confirm('是否确认修改值班教师？', '提示').then(() => {
                this.setItem(item, this.teacherIds, parentDay)
              }).catch(() => {
                return false
              })
            }
          }
        } else {
          // 原本为空，则直接赋值
          this.setItem(item, this.teacherIds, parentDay)
        }
      } else {
        if (item?.teacherInfoList?.length) {
          // 未选择，则清空
          this.setItem(item, [], parentDay)
        } else {
          this.$message.warning({
            message: '请先选择值班教师！',
            group: true
          })
          return false
        }
      }
    },
    // 设置值班教师 数据 ，需要同步allScheduleList 和scheduleList
    setItem(target, data, parentDay) {
      const _selects = this.teacherList.filter(item => this.teacherIds.includes(item.userId))
      target.userIdJson = JSON.stringify(data)
      this.$set(target, 'teacherInfoList', data.length ? _selects : data)
      this.changeSchedule(parentDay)
      this.$refs.batchScheduleRef.changeSchedule(parentDay)
    },
    save: throttle(function() {
      this.$refs.batchScheduleRef.replaceSchedule()
      this.$confirm(`此操作将新增学期排班表（${this.formData.dateRange[0]}至${this.formData.dateRange[1]}）, 是否继续?`, '提示').then(async () => {
        this.loading = true
        // 用于创建失败时 把 上一步id删除
        let _id
        try {
          // 保存模板和模板创建日期等,用于获取学期排班id  termWorkId赋值给下一个接口
          const res = await saveScheduleRecordByIdApi(this.formData)
          // 拿到id 才能进行下一步
          if (res.data) {
            _id = res.data
            let result = []
            const daysDiff = this.$moment(this.formData.dateRange[1]).diff(this.formData.dateRange[0], 'days')
            for (let i = 0; i <= daysDiff; i++) {
              const _targetDay = this.$moment(this.formData.dateRange[0]).add(i, 'days').format('YYYY-MM-DD')
              let hasTargetDay = this.allScheduleList.find(item => item.day === _targetDay)
              if (hasTargetDay) {
                hasTargetDay = cloneDeep(hasTargetDay)
                hasTargetDay.list.forEach((item) => {
                  item.termWorkId = res.data
                  item.teacherInfoList = []
                })
                result = [...result, ...hasTargetDay.list]
              } else {
                const _weekDayName = (this.$moment(_targetDay).weekday() + 1) + ''
                const weekCount = this.$moment(_targetDay).diff(this.startDayOfMonday, 'weeks') + 1
                const _targetSchedule = cloneDeep(this.scheduleModel.find(item => item.weekDayName === _weekDayName))
                _targetSchedule.list.forEach(item => {
                  item.weekDayName = _weekDayName
                  item.day = _targetDay
                  item.weekCount = weekCount
                  item.termWorkId = res.data
                  item.userIdJson = item.userIdJson || '[]'
                  item.teacherInfoList = []
                })
                result = [...result, ..._targetSchedule.list]
              }
            }

            // 批量保存排版
            await batchSavePsychoScheduleApi(result)
            this.$message.success('保存成功！')
          }
          this.closed()
        } catch (e) {
          // 批量新增重复时，删除新增的排班记录
          if (_id) {
            try {
              await delScheduleRecordByIdApi(_id)
            } catch (e) {
            } finally {
            }
          }
          console.error(e)
        } finally {
          this.loading = false
        }
      }).catch()
    }, 1000)
  }
}
</script>
<style lang="scss">
.psychology-schedule-dialog {
  .el-dialog {
    margin-top: 20px !important;
  }

  .el-step__description {
    font-size: 14px;
    padding-right: 0;
    margin-bottom: 2em;

    &.is-finish {
      color: #404040;
    }
  }

  .el-collapse {
    border: 0;
    border-radius: 8px;
    padding: 2px;
    box-shadow: 0 0 0 1px #EBEEF5 inset;
    box-sizing: border-box;

    .el-collapse-item__header {
      color: $color-info;
      font-size: 16px;
      text-indent: 1rem;
    }

    .el-collapse-item__wrap {
      border-bottom: 0;
    }
  }

  .days-item {
    cursor: pointer;

    &:hover {
      background: #f5f7fa;
    }
  }
}
</style>
