<!--根据周次生成值班表-->
<template>
  <div class="batch-schedule mt-1">
    <el-row v-if="scheduleList.length">
      <el-col :span="6">
        <el-form-item label="值班教师" label-width="90px">
          <el-select v-model="teacherIds" clearable multiple :multiple-limit="4">
            <el-option
              v-for="{name,userId} in teacherList"
              :label="name"
              :value="userId"
              :key="userId" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item label="周一">
          <el-date-picker
            v-model="monday"
            value-format="yyyy-MM-dd"
            type="week"
            format="yyyy-MM-dd"
            placeholder="选择周"
            :default-value="formData.dateRange[0]"
            :picker-options="pickerOptions"
            @change="renderSchedule" />
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item label="周日(自动获取)" label-width="120px">
          <el-input disabled v-model="sunday"
          />
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="周次">
          第{{ currentWeekCount }}周（共{{ formData.weekCount }}周）
        </el-form-item>
      </el-col>
      <el-col :span="3">
        <el-button class="fr" @click="clear('scheduleList')">清空排班表</el-button>
      </el-col>
    </el-row>
    <div v-if="scheduleList.length&&show" class="schedule-grid psycho">
      <div class="schedule-grid__header flex" style="background: #f3f5fb;">
        <div class="schedule-grid__label" style="line-height: 24px;text-align: center;"><b>时间段</b></div>
        <div class="schedule-grid__label" v-for="(item,index) in scheduleList" :key="index">
          <b class="week-name">周{{ $store.state.systemParam.weekList[+item.weekDayName] }}
            <el-link title="清空当日排班" type="danger" class="el-icon-delete" @click="clearColumn(item)"></el-link>
          </b>
          <p class="week-date">{{ item.day }}</p>
        </div>
      </div>
      <div class="schedule-grid__body flex-column">
        <div class="schedule-grid__wrap  flex schedule-grid__body-top">
          <div class="schedule-grid__column" style="background: #f3f5fb;">
            <div class="schedule-grid__item time-stamp" v-for="(item,index) in classHours"
                 :key="index">
              <b>{{ item }}</b>
            </div>
          </div>
          <div class="schedule-grid__column" v-for="item in scheduleList" :key="item.id">
            <div class="schedule-grid__item flex-column days-item"
                 v-for="(subItem,subIndex) in item.list" :key="subIndex" @click="handleCheck(subItem,'add')">
              <template v-if="subItem.teacherInfoList">
                <el-tag v-for="{userId,name} in subItem.teacherInfoList" :key="userId" :type="getTeacherTag(userId)">
                  {{ name
                  }}
                </el-tag>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { cloneDeep, sortBy } from 'lodash'
import psychoSchedule from '@/vue/mixins/psycho-schedule'

export default {
  name: 'batchSchedule',
  mixins: [psychoSchedule],
  props: {
    startDayOfMonday: String, // 周期首周的周一日期
    formData: {
      type: Object,
      default: () => ({})
    },
    model: { // 模板排班表
      type: Array,
      default: () => ([])
    },
    allScheduleList: { // 整个学期的班表存放地
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      show: false,
      currentWeekCount: 1, // 选中的周次（区别于 formData.weekCount）
      scheduleList: [],
      monday: '', // 选中周的周一
      sunday: '', // 选中周的周日
      pickerOptions: {
        disabledDate: (time) => {
          const timesValue = this.$moment(time).valueOf()
          const _startTime = this.$moment(this.formData.dateRange[0]).startOf('week').valueOf()
          const _endTime = this.$moment(this.formData.dateRange[1]).endOf('week').valueOf()
          return timesValue < _startTime || timesValue > _endTime
        }
      }
    }
  },
  methods: {

    /* 如果模板修改，则需要把 allScheduleList/scheduleList里的数据也同步上，因此需要单独加方法 改 getCheck  */
    changeSchedule(parentDay) {
      if (this.scheduleList.length) {
        this.show = false
        const index = this.scheduleList.findIndex(item => item.weekDayName === parentDay.weekDayName)
        if (this.scheduleList[index]) this.scheduleList[index] = cloneDeep(parentDay)
        this.show = true
      }
    },
    // 判断 当前scheduleList是否曾经被编辑，是的话替换旧数据
    replaceSchedule() {
      const result = cloneDeep(this.allScheduleList)
      for (const item of this.scheduleList) {
        let hasDay = result.find(allItem => allItem.day === item.day)
        if (hasDay) {
          hasDay = item
        } else {
          result.push(item)
        }
      }
      sortBy(result, ['day'])
      this.$emit('update:allScheduleList', result)
    },
    // 根据 选中的周一生成排班表
    renderSchedule() {
      if (this.scheduleList.length) {
        this.replaceSchedule()
      }
      this.sunday = this.$moment(this.monday).endOf('week').format('YYYY-MM-DD')
      const _result = []
      for (let i = 0; i < 7; i++) {
        const _targetDay = this.$moment(this.monday).add(i, 'days').format('YYYY-MM-DD')
        // 周次
        this.currentWeekCount = this.$moment(_targetDay).diff(this.startDayOfMonday, 'weeks') + 1
        const _hasDay = this.allScheduleList.find(item => item.day === _targetDay)
        if (_hasDay) {
          _result.push(_hasDay)
        } else {
          // 是否早于周期
          const isEarlier = this.$moment(_targetDay).diff(this.formData.dateRange[0], 'days') < 0
          // 是否晚与周期
          const isLater = this.$moment(this.formData.dateRange[1]).diff(_targetDay, 'days') < 0
          if (!(isEarlier || isLater)) {
            const _weekDayName = i + 1
            const _copy = cloneDeep(this.model.find(item => +item.weekDayName === _weekDayName))
            _copy.day = _targetDay
            _copy.list.forEach((item) => {
              // item.campusId = this.formData.campusId
              // item.term = this.formData.term
              item.weekDayName = _weekDayName
              item.day = _targetDay
              item.weekCount = this.currentWeekCount
            })
            _result.push(_copy)
          }
        }
      }
      this.scheduleList = _result
      this.show = true
    }
  }
}
</script>
<style lang="scss">
</style>
