<!--  本周班表  -->
<template>
  <div class="current-schedule" v-loading="loading">
    <el-form :model="queryInfo" :disabled="isEditing" inline label-width="50px">
      <el-form-item label="校区" prop="campusId">
        <el-radio-group v-model="queryInfo.campusId" @change="renderTable">
          <el-radio-button v-for="{campusName, id} in campusList" :key="id" :label="id">{{ campusName }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="周一">
        <el-date-picker
          v-model="queryInfo.startDate"
          value-format="yyyy-MM-dd"
          type="week"
          format="yyyy-MM-dd"
          placeholder="选择周"
          @change="renderTable"/>
      </el-form-item>
      <el-form-item label="周日(自动获取)" label-width="120px">
        <el-input disabled v-model="queryInfo.endDate"
        />
      </el-form-item>
    </el-form>
    <!--  编辑排班,工具条  -->
    <div v-if="isEditing" class="mb-1 font-right" style="overflow: hidden">
      <div class="fl">
        选择值班教师：
        <el-select v-model="teacherIds" clearable multiple :multiple-limit="4" style="width: 500px;">
          <el-option
            v-for="{name,userId} in teacherList"
            :label="name"
            :value="userId"
            :key="userId" />
        </el-select>
      </div>
      <el-button  v-permission="['psy:schedule:edit']" @click="clear('scheduleList')">一键清空</el-button>
      <el-button @click="cancel">取消</el-button>
      <el-button v-permission="['psy:schedule:edit']" type="primary" @click="save">保存</el-button>
    </div>
    <div v-else class="mb-1 font-right">
      <el-button v-permission="['psy:schedule:edit']" v-show="this.scheduleList.length" type="primary" @click="isEditing=true">编辑</el-button>
    </div>
    <div v-if="scheduleList.length" class="schedule-grid psycho">
      <div class="schedule-grid__header flex">
        <div class="schedule-grid__label" style="line-height: 48px;text-align: center;"><b>时间段</b></div>
        <div class="schedule-grid__label" v-for="(item,index) in scheduleList" :key="index">
          <b class="week-name">{{ item.week }}
            <el-link v-if="isEditing" title="清空当日排班" type="danger" class="el-icon-delete" @click="clearColumn(item)"></el-link>
          </b>
          <span class="week-date">{{ item.day }}</span>
        </div>
      </div>
      <div class="schedule-grid__body flex-column">
        <div class="schedule-grid__wrap  flex schedule-grid__body-top">
          <div class="schedule-grid__column">
            <div class="schedule-grid__item time-stamp" v-for="(item,index) in scheduleList[0].list"
                 :key="index">
              <b>{{ item.classHourName }}</b>
            </div>
          </div>
          <div class="schedule-grid__column" v-for="(item,index) in scheduleList" :key="index">
            <div :class="['schedule-grid__item','flex-column',{'is-editing':isEditing}]"
                 v-for="subItem in item.list" :key="subItem.id" @click="handleCheck(subItem,'edit')">
              <template v-if="subItem.teacherInfoList">
                <el-tag v-for="{userId,name,offFlag} in subItem.teacherInfoList" :key="userId" :type="getTeacherTag(userId)">{{ name }}
                  <template v-if="offFlag">【休假】</template>
                </el-tag>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-empty v-else description="暂无排班" />

    <psychology-schedule-dialog ref="dialogRef"
                                :visible.sync="visibleDialog"
                                v-if="visibleDialog" @on-close="renderTable" />
  </div>
</template>

<script>
import {
  delPsychoScheduleByIdApi,
  listPsychoScheduleApi,
  batchSavePsychoScheduleApi
} from '@/api/student-manage/psychology-schedule-api'
import PsychologyScheduleDialog
  from '@/views/pages/studentManage/psychology-schedule/components/psychologyScheduleDialog.vue'
import { throttle } from 'lodash'
import psychoSchedule from '@/vue/mixins/psycho-schedule'

export default {
  name: 'currentSchedule',
  components: { PsychologyScheduleDialog },
  mixins: [psychoSchedule],
  data() {
    return {
      loading: false, // 遮罩
      isEditing: false, // 是否编辑本周排班
      visibleDialog: false, // 打开新增弹窗
      queryInfo: {
        campusId: null,
        startDate: '',
        endDate: ''
      },
      scheduleList: []
    }
  },
  async mounted() {
    await this.getTeacherList()
    await this.getCampusList()
    await this.renderTable()
  },
  methods: {
    delPsychoScheduleByIdApi, // 删除api
    async renderTable() {
      this.loading = true
      if (this.queryInfo.startDate) {
        this.queryInfo.endDate = this.$moment(this.queryInfo.startDate).endOf('week').format('YYYY-MM-DD')
      } else {
        // 获取本周开始和结束的日期
        this.queryInfo.startDate = this.$moment().startOf('week').format('YYYY-MM-DD')
        this.queryInfo.endDate = this.$moment().endOf('week').format('YYYY-MM-DD')
      }
      this.queryInfo.campusId = this.queryInfo.campusId || this.campusList[1].id
      try {
        const { data } = await listPsychoScheduleApi(this.queryInfo)
        this.setSchedule(data)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    // 获取校区
    async getCampusList() {
      try {
        const res = await this.$http.getCampusListQuery({})
        this.campusList = res.data
      } catch (e) {
      }
    },
    /* 生成排班表
    *  @params {array} arr 获取的格子日期班表
    * */
    setSchedule(arr) {
      const _list = arr
      if (!_list.length) {
        this.scheduleList = []
        return
      }
      const _startDate = this.$moment(_list[0].day).startOf('week').format('YYYY-MM-DD')
      const result = []
      for (let i = 0; i < 7; i++) {
        const _currentDay = this.$moment(_startDate).add(i, 'days').format('YYYY-MM-DD')
        const _todaySchedule = _list.filter(item => item.day === _currentDay)
        const _todayTimeline = []
        // 一天4个排班时，有排班就插入，无就插入个空的占位
        this.classHours.forEach((hourName) => {
          const _course = _todaySchedule.find(item => item.classHourName === hourName)
          if (_course) {
            _todayTimeline.push(_course)
          } else {
            _todayTimeline.push({
              classHourName: hourName,
              day: _currentDay,
              id: hourName
            })
          }
        })
        // 把每天的排版按照日期去插入到result中
        result.push({
          week: '周' + this.$store.state.systemParam.weekList[this.$moment(_currentDay).days()],
          day: _currentDay,
          list: _todayTimeline
        })
      }
      this.scheduleList = result
    },
    // 取消编辑
    cancel() {
      this.$confirm('是否确认取消，已编辑的数据将不保存。', '取消确认').then(() => {
        this.isEditing = false
        this.renderTable()
      })
    },
    save: throttle(function() {
      if (this.loading) {
        this.$message.error('请勿重复提交!')
        return
      }
      this.loading = true
      this.$confirm(`此操作将新增（周期：${this.queryInfo.startDate}至${this.queryInfo.endDate}）, 是否继续?`, '提示', {
        dangerouslyUseHTMLString: true
      }).then(async () => {
        this.isEditing = false
        let _result = []
        this.scheduleList.forEach(item => {
          _result = [..._result, ...item.list]
        })
        try {
          await batchSavePsychoScheduleApi(_result)
          this.$message.success('排班表保存成功')
          await this.renderTable()
        } catch (e) {
        } finally {
          this.loading = false
        }
      }).catch()
    }, 1000)

  }
}
</script>
<style lang="scss">
.current-schedule {
  width: 100%;

  .schedule-grid .schedule-grid__column .schedule-grid__item {
    position: relative;
    line-height: 90px;
    padding: 16px 0 10px 0;

    &.is-editing {
      cursor: pointer;

      &:hover {
        background: #f5f7fa;
      }
    }

    .day-flag {
      position: absolute;
      left: 0;
      top: 0;
      padding: 4px;
      height: auto;
      line-height: 1.2;
      border-radius: 0 0 4px 0;
    }
  }
}
</style>
