<!--编辑排班-->
<template>
  <div>
    <el-dialog
      v-loading="loading"
      class="edit-psycho-schedule-dialog"
      :title="`编辑学期排班表`"
      :visible.sync="show"
      :close-on-click-modal="false"
      :before-close="cancel"
      width="420px"
    >
      <el-form ref="form" :model="formData" :rules="rules" label-width="60px">
        <el-form-item label="校区" prop="campusId">
          <el-radio-group v-model="formData.campusId">
            <el-radio-button v-for="{campusName, id} in campusList" :key="id" :label="id">{{ campusName }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <!--学年 只用于筛选，不做他用-->
        <el-form-item label="学年">
          {{ formData.schoolYear }}
        </el-form-item>
        <el-form-item label="学期" prop="term">
          <el-select v-model="formData.term">
            <el-option v-for="item in termList" :key="item.id" :label="`第${item.paramValue}学期`"
                       :value="item.paramValue" />
          </el-select>
        </el-form-item>
        <el-form-item label="周期">
          {{formData.startTime}}~{{formData.endTime}}
        </el-form-item>
        <el-form-item label="总周次">
          {{formData.weekCount}}
        </el-form-item>
      </el-form>
      <template #footer>
        <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
        <el-button type="primary" @click="save('学期排班',saveScheduleRecordByIdApi)">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { getParamsList } from '@/api/sysParams'
import { saveScheduleRecordByIdApi, getPsychoScheduleByIdApi } from '@/api/student-manage/psychology-schedule-api'

export default {
  name: 'editPsychoScheduleDialog',
  mixins: [dialog],
  props: {
    departmentList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      loading: false,
      termList: [],
      campusList: [],
      formData: {
        id: '',
        week: '',
        campusName: '',
        startTime: '',
        endTime: '',
        campusId: '',
        schoolYear: '',
        term: '',
        scheduleTemplate: '', // 模板
        weekCount: 0 // 获取生成的周次总数
      },
      rules: {
        term: {
          required: true,
          message: '请选择学期',
          trigger: 'blur'
        }
      }
    }
  },
  async mounted() {
    await Promise.all([
      this.getTermList(),
      this.getCampusList()
    ])
    await this.getDataById(this.id, getPsychoScheduleByIdApi)
  },
  methods: {
    saveScheduleRecordByIdApi,
    // 获取校区
    async getCampusList() {
      try {
        const res = await this.$http.getCampusListQuery({})
        this.campusList = res.data
      } catch (e) {
      }
    },
    // 获取学期--termList
    async getTermList() {
      try {
        const { data } = await getParamsList({ keyword: 'term' })
        this.termList = data.list
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>
<style lang="scss">
.edit-psycho-schedule-dialog {

}
</style>
